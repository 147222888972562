export default (function () {
    $(document).ready(function () {
        $('.search-btn').on('click', function (e) {
            e.preventDefault();
            all_results = false;
            getResults(0, false);
        });
        $('.load-more-btn').on('click', function (e) {
            e.preventDefault();
            let offset = getOffset();
            getResults(offset, true);
        });

        $(window).scroll(function () {
            if ($(window).scrollTop() + $(window).height() >= $(document).height()) {
                let offset = getOffset();
                getResults(offset, true);
            }
        });

        $(document).on('click', '.goTop', function () {
            goTop();
        })
    });

}());

function getOffset() {
    let offset = $('#search_results_container').find('.search_result_item').length;

    return parseInt(offset);
}

var all_results = false;

function getResults(offset, append) {
    if (all_results) {
        return false;
    }
    var formData = $('#search_form').serialize();
    formData = formData + '&' + 'offset=' + offset;

    $(".load-more-btn").attr("disabled", true);


    $.ajax({
        method: 'GET',
        url: Routing.generate('property_search'),
        data: formData,
    }).done(function (data) {
            $(".load-more-btn").attr("disabled", false);
            if (append === true) {
                $('#search_results_container').append(data.results);
            } else {
                $('#search_results_container').html(data.results);
            }
            all_results = $('#search_results_container').find('.search_result_item').length >= data.count;
            if (all_results) {
                $('.load-more-btn').hide();
                let message = $('<div class="centerFlex">Nu mai sunt rezultate pentru criteriile cautate!  <button class="btn btn-sm btn-outline-secondary goTop" title="Inapoi la cautare">Înapoi la cautare</button></div>');
                $('#search_results_container').append(message);
            } else {
                $('.load-more-btn').show();
            }
        }
    )
}

function goTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}



